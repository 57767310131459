import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import applicationReducer from "./reducers/application/ApplicationSlice";

export const store = configureStore({
    reducer: {
        application: applicationReducer
    }
});

store.subscribe(() => {
  // persist your state
  try {
    localStorage.setItem(
      "deactivated",
      store.getState().application.deactivated.toString()
    );
  } catch (e) {
    console.log("🚀 ~ file: store.ts:18 ~ store.subscribe ~ e:", e);
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
