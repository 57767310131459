import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



export interface ApplicationState {
  account: string;
  status: 'idle' | 'loading' | 'failed';
  deactivated: number, 
  loading:boolean
}

let deactivated = 1;
if(localStorage.getItem('deactivated')) {
  deactivated = localStorage.getItem('deactivated') !== null && localStorage.getItem('deactivated') == '1' ? 1 : 0;
}

const initialState: ApplicationState = {
  account: '',
  // sidebarExtended: true,
  status: 'idle',
  deactivated: deactivated,
  loading:false
};


export const connectAsync = createAsyncThunk(
  'counter/fetchCompanyInterest',
  async (companyInterest:any) => {
    const response = await companyInterest();
    console.log("🚀 ~ file: ContractSlice.ts:22 ~ response:", response)
    // The value we return becomes the `fulfilled` action payload
    return "response.data";
  }
);


export const appliationSlice = createSlice({
  name: 'application',
  initialState,

    // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccount: (state, action) => {
        state.account = action.payload;
    },
    removeAccount: (state, action) => {
        state.account = action.payload;
    },
    setDeactivated: (state, action) => {
      state.deactivated = action.payload;
    },
    setContractLoading: (state, action) => {
      state.loading = action.payload;
    }
  },

   // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(connectAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(connectAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.account = action.payload;
      })
      .addCase(connectAsync.rejected, (state) => {
        state.status = 'failed';
      });
  }
})

export const {setAccount, removeAccount, setDeactivated, setContractLoading} = appliationSlice.actions;

export default appliationSlice.reducer;