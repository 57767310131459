import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Dice = lazy(() => import("./pages/dice/DicePage"));
const LoadingPage = lazy(() => import("./pages/LoadingPage"));
const NoMatch = lazy(() => import("./pages/NoMatch"));

function App() {
  return (
    <Suspense fallback={<LoadingPage/>}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dice" element={<Dice />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
}

export default App;
